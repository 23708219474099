<script>
import mixpanel from "mixpanel-browser/src/loader-module";

export default {
  data() {
    return {};
  },
  methods: {
    handleClick() {
      console.log('click')
      mixpanel.track('수리 요청하기2')
      window.Kakao.Channel.chat({ channelPublicId: '_Taxexmxj',});
    }
  }
};
</script>

<template>
  <div>
    <!-- Testimonial start -->
    <section class="section" id="testimonial">
      <div class="container">
        <div class="row justify-content-center mb-4">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">고객님들의 이용 후기</h2>
            <p class="text-muted">
              많은 분들이 고쳐줘를 좋아해 주세요
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <span class="badge badge-soft-primary">도배, 페인트</span>
                <h4 class="font-size-22 my-4">
                  <a href="javascript: void(0);"
                  >수리업체 찾아주는 서비스 중 최고입니다!</a
                  >
                </h4>
                <p class="text-muted">
                  일일이 전화하기는 시간도 많이 들고 잘 찾기도 힘들었어요.
                </p>
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                      src="@/assets/images/user/icons8-user-64.png"
                      class="rounded-circle avatar-sm me-3"
                      alt="..."
                  />
                  <div class="flex-body">
                    <h5 class="font-size-17 mb-0">책모양</h5>
                    <p class="text-muted mb-0 font-size-14">
                      제주시 아라동
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <span class="badge badge-soft-primary">수도 교체</span>
                <h4 class="font-size-22 my-4">
                  <a href="javascript: void(0);"
                  >다양한 업체를 가격비교 해주셔서 좋았습니다.</a
                  >
                </h4>
                <p class="text-muted">
                  어느 정도 비용이 들어갈지 예상을 못했었는데, 여러 곳을 알려주셔서 비교가 가능했어요
                </p>
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                      src="@/assets/images/user/icons8-user-64.png"
                      class="rounded-circle avatar-sm me-3"
                      alt="..."
                  />
                  <div class="flex-body">
                    <h5 class="font-size-17 mb-0">YSW</h5>
                    <p class="text-muted mb-0 font-size-14">
                      제주시 연동
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

          <div class="col-lg-4">
            <div class="card mt-4 border-0 shadow">
              <div class="card-body p-4">
                <span class="badge badge-soft-primary">변기 수리</span>
                <h4 class="font-size-22 my-4">
                  <a href="javascript: void(0);"
                  >업체를 선정, 대략적인 가격을 알려주는 것, 번호를 알려주시는 것</a
                  >
                </h4>
                <p class="text-muted">
                  친절한 업체를 선정해서 알려주시는 것 같았어요. 전화 드려보니 정말 그랬거든요.
                </p>
                <div class="d-flex align-items-center mt-4 pt-2">
                  <img
                      src="@/assets/images/user/icons8-user-64.png"
                      class="rounded-circle avatar-sm me-3"
                      alt="..."
                  />
                  <div class="flex-body">
                    <h5 class="font-size-17 mb-0">개미개미</h5>
                    <p class="text-muted mb-0 font-size-14">
                      제주시 노형동
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->

        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Testimonial end -->

    <!-- CTA start -->
    <section
        class="section bg-center w-100 bg-light"
        :style="{
        'background-image':
          'url(' + require('@/assets/images/cta-bg.png') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card bg-gradient-primary text-center border-0">
              <div
                  class="bg-overlay-img"
                  :style="{
                  'background-image':
                    'url(' + require('@/assets/images/demos.png') + ')',
                }"
              ></div>
              <div class="card-body mx-auto p-sm-5 p-4">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div class="p-3">
                      <h2 class="text-white mb-4">
                        지금 고쳐줘에 문의해 보세요.
                      </h2>
                      <p class="text-white-70 font-size-16 mb-4 pb-3">
                        수리에 관한 문제, 이제는 고민하지 마세요.
                        <br />
                        신뢰, 품질, 편의 모두 만족시켜 드립니다.
                      </p>
                      <a
                          href="javascript: void(0);"
                          @click="handleClick"
                          class="btn btn-light rounded-pill"
                      >수리 요청하기</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- CTA end -->

  </div>
</template>