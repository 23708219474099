<script>
import mixpanel from "mixpanel-browser/src/loader-module";

export default {
  data() {
    return {};
  },
  methods: {
    handleClick(text) {
      console.log('click')
      mixpanel.track(text)
      window.Kakao.Channel.chat({ channelPublicId: '_Taxexmxj',});
    }
  }
};
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="caution">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">이런적 없으셨나요?</h2>
            <p class="text-muted">
            </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
            <h2 class="mb-4">집수리 발생시<br />어디에 문의를 해야할까?</h2>
            <p class="text-muted mb-5">
              벽지손상, 화장실 변기 깨짐, 타일 손상, 중문 설치 등
              <br />
              수리 문제가 발생했을 시 어느 업체에 문의를 해야할 지 막막하시죠?
              <br />
              이젠 간단히 해결해 보세요.
              <br />
            </p>
            <a href="#" @click="handleClick" class="btn btn-primary me-2"
            >수리 문의하기
              <arrow-right-icon class="icon-sm ms-1"></arrow-right-icon
              ></a>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img
                    src="@/assets/images/female-hands-typing-keyboard-netbook-close-up-view-business-concept.jpg"
                    alt=""
                    class="img-fluid ms-auto rounded shadow"
                />
              </div>
              <img
                  src="@/assets/images/dot-img.png"
                  alt=""
                  class="dot-img-left"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row align-items-center section pb-0">
          <div class="col-md-6">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img
                    src="@/assets/images/businessperson.jpg"
                    alt=""
                    class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                  src="@/assets/images/dot-img.png"
                  alt=""
                  class="dot-img-right"
              />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto">
            <h2 class="mb-4">
              미리 어느 정도 비용이 드는지 <br />확인할 수 없을까?
            </h2>
            <p class="text-muted mb-5">
              수리 문제에 대한 사전 견적 비용 비교하고 싶은데,
              <br />
              확인하기 번거롭고, 적당한 가격인지 모르실 때가 많으시죠?
              <br />
              지금 바로 업체들의 사전 견적을 알아보세요.
            </p>
            <a href="#" @click="handleClick" class="btn btn-primary me-2"
            >견적 요청하기
              <arrow-right-icon class="icon-sm ms-1"></arrow-right-icon
              ></a>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->
    <!-- Cta end -->
  </div>
</template>